const marketingElement = document.querySelector(".main__marketing");
const technologyElement = document.querySelector(".main__technology");
export const marketingLink = "https://dna.digitalnewagency.com/en/marketing/";
export const technologyLink = "https://dnatechnology.io/";

marketingElement.addEventListener(
  "click",
  () => (window.location.href = marketingLink)
);
technologyElement.addEventListener(
  "click",
  () => (window.location.href = technologyLink)
);
